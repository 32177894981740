import React from 'react';

const About = () => {
  const containerStyle = {
    backgroundColor: '#1F1F1F',
    color: '#FFFFFF',
    padding: '30px',
    borderRadius: '12px',
    maxWidth: '800px',
    margin: 'auto',
    lineHeight: '1.6',
    textAlign: 'left',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
  };

  const headingStyle = {
    borderBottom: '3px solid #FFFFFF',
    paddingBottom: '10px',
    marginBottom: '20px',
    fontSize: '2.5em',
    fontWeight: 'bold',
  };

  const paragraphStyle = {
    marginBottom: '15px',
    fontSize: '1.1em',
  };

  const strongStyle = {
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>About Nyx Play</h1>
      <p style={paragraphStyle}>
        Welcome to Nyx Play, your ultimate destination for <span style={strongStyle}>free HTML5 games</span>. Our platform offers a diverse collection of <span style={strongStyle}>instant HTML5 games</span> that can be played directly in your browser on both <span style={strongStyle}>PC</span> and <span style={strongStyle}>mobile devices</span>. We pride ourselves on providing a seamless gaming experience, allowing you to enjoy your favorite games without the hassle of downloads or installations. Our games are categorized as <span style={strongStyle}>casual games</span>, <span style={strongStyle}>puzzle games</span>, and <span style={strongStyle}>action games</span>.
      </p>
      <p style={paragraphStyle}>
        At Nyx Play, we understand the importance of variety in gaming. That's why our catalog includes a wide range of genres, including <span style={strongStyle}>soft games</span> and <span style={strongStyle}>mobile-friendly games</span>. Whether you are looking for thrilling challenges or relaxing puzzles, we have something for everyone. Our <span style={strongStyle}>no download games</span> and <span style={strongStyle}>free browser games</span> deliver high-quality graphics and immersive gameplay that keeps players coming back for more.
      </p>
      <p style={paragraphStyle}>
        Join our growing community of gamers who share your passion for online gaming. We regularly update our library with new titles, ensuring that you always have fresh content to explore. With our <span style={strongStyle}>instant play games</span>, you can enjoy quick gaming sessions without any hassle.
      </p>
      <p style={paragraphStyle}>
        Our mission is to create an inclusive gaming environment where players of all ages can discover and enjoy <span style={strongStyle}>HTML5 games</span> in a safe and friendly space. We are committed to protecting your privacy and providing clear <a href="/privacy-policy" style={{ color: '#1E90FF' }}>Privacy Policy</a> and <a href="/terms-of-service" style={{ color: '#1E90FF' }}>Terms of Service</a> for transparency.
      </p>
      <p style={paragraphStyle}>
        Ready to embark on your gaming adventure? Explore our extensive game list today and experience the best of <span style={strongStyle}>online games</span> at Nyx Play!
      </p>
    </div>
  );
};

export default About;
