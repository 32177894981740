import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import the toast styles
import AdsenseAd from '../../components/AdsenseAd';
import './RobloxArticles.css';

const AdoptMeArticle = () => {
  // Function to copy code to clipboard and simulate ad click
  const copyToClipboard = (code) => {
    if (navigator.clipboard) {
      // Use the Clipboard API if available
      navigator.clipboard.writeText(code)
        .then(() => {
          toast.success(`${code} copied to clipboard!`);
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          toast.error('Failed to copy code.');
        });
    } else {
      // Fallback for unsupported environments
      const tempInput = document.createElement('input');
      tempInput.value = code;
      document.body.appendChild(tempInput);
      tempInput.select();
      try {
        document.execCommand('copy');
        toast.success(`${code} copied to clipboard!`);
      } catch (err) {
        console.error('Failed to copy: ', err);
        toast.error('Failed to copy code.');
      } finally {
        document.body.removeChild(tempInput);
      }
    }
  };

  // Array of codes and their definitions
  const codes = [
    { code: 'GIFTUNWRAP', description: '70% off your next gift purchase.' },
    { code: '1BILLION', description: '2000 Bucks.' },
    { code: 'FINGERSCROSS', description: 'Free items.' },
    { code: 'DUSK', description: 'Free Dusk Pet.' },
    { code: 'LETSGO', description: 'Free gift.' },
    { code: 'FALL2023', description: 'Fall-themed items.' },
    { code: 'TREASURE', description: 'Free money.' },
    { code: 'NEWYEAR2024', description: 'New Year-themed rewards.' },
    { code: 'ALMOSTTHERE', description: 'Free in-game currency.' },
    { code: 'MEGA', description: 'Free Mega Neon Pet.' },
    { code: 'BLOXYAWARDS', description: 'Free Bloxy Award Pet.' },
    // Add any additional codes here
  ];

  return (
    <div className="article-container">
      <Helmet>
        <title>Adopt Me: Latest Codes & Tips for September 2024</title>
        <meta name="description" content="Discover the latest codes for Adopt Me, tips for pet care, and exciting HTML games to play for free!" />
        <meta name="keywords" content="Adopt Me, Roblox, codes, tips, pets, gaming, HTML games, free games, pet care, trading, events" />
      </Helmet>

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={true} closeOnClick={true} pauseOnHover={false} draggable={false} />

      <h1>Adopt Me: Latest Codes & Tips for September 2024</h1>

      {/* Top Adsense Ad */}
      <div className="ads-container-top">
        <AdsenseAd id="adsense-top" slot="1456784065" />
      </div>

      <p>
        <strong>Adopt Me</strong> is a beloved game on Roblox where players can adopt pets, build homes, and trade items. The game features a vibrant community and regular updates, making it a fantastic experience for players of all ages. Using the latest codes can provide valuable rewards and help you progress faster. In this article, we’ll share the most recent codes and essential tips for navigating Adopt Me.
      </p>

      <h2>Latest Adopt Me Codes</h2>
      <p>Each of these codes offers unique benefits that can enhance your gameplay:</p>

      {/* Codes Table */}
      <table className="codes-table">
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {codes.map(({ code, description }) => (
            <tr key={code}>
              <td><strong>{code}</strong></td>
              <td>{description}</td>
              <td>
                <button onClick={() => copyToClipboard(code)}>Copy</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p>
        Remember to redeem these codes quickly, as they can expire soon. Check back often for updates on new codes that might be released with special events!
      </p>

      <h2>How to Redeem Adopt Me Codes</h2>
      <ol>
        <li>Launch <strong>Adopt Me</strong> on Roblox.</li>
        <li>Click the Twitter icon on the side of the screen.</li>
        <li>Enter the code into the box and press enter.</li>
      </ol>

      <h2>Why Use Codes?</h2>
      <p>
        Using codes in <strong>Adopt Me</strong> can give you free in-game currency, pets, and exclusive items, enhancing your gameplay and allowing you to create the ultimate virtual home.
      </p>

      <h2>Tips for Success in Adopt Me</h2>
      <p>
        Focus on completing daily tasks and participating in events to earn more rewards. Trading with other players is crucial; always evaluate the worth of your items and pets. Engage with the community for tips and advice!
      </p>

      <h2>Community and Updates</h2>
      <p>
        The Adopt Me community is active and provides valuable insights through forums and social media. Stay connected to learn about upcoming events and updates to ensure you don’t miss out on limited-time offers and new pets!
      </p>

      {/* Bottom Adsense Ad */}
      <div className="ads-container-bottom">
        <AdsenseAd id="adsense-bottom" slot="6918963927" />
      </div>

      {/* Promotional Section */}
      <div className="promotion-section">
        <h2>Exciting HTML Games: Free to Play!</h2>
        <p>Check out our main website for a variety of exciting HTML games that you can play for free! Enjoy thrilling adventures and fun challenges. Don’t miss out on the fun—play now and discover new games every day!</p>
        <a href="/" className="promotion-button">Play Now</a>
      </div>
    </div>
  );
};

export default AdoptMeArticle;
