import React from 'react';

const TermsOfService = () => {
  const containerStyle = {
    backgroundColor: '#1F1F1F',
    color: '#FFFFFF',
    padding: '30px',
    borderRadius: '12px',
    maxWidth: '800px',
    margin: 'auto',
    lineHeight: '1.6',
    textAlign: 'left',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
  };

  const headingStyle = {
    borderBottom: '3px solid #FFFFFF',
    paddingBottom: '8px',
    marginBottom: '24px',
    fontSize: '2em',
  };

  const subHeadingStyle = {
    marginBottom: '12px',
    fontSize: '1.5em',
  };

  const linkStyle = {
    color: '#1E90FF',
    textDecoration: 'none',
  };


  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Terms of Service</h1>
      <p>Welcome to Nyx Play! These terms and conditions outline the rules and regulations for the use of our website.</p>
      
      <h2 style={subHeadingStyle}>1. Acceptance of Terms</h2>
      <p>By accessing or using the Nyx Play website, you agree to be bound by these Terms of Service and our Privacy Policy.</p>
      
      <h2 style={subHeadingStyle}>2. Use of Our Service</h2>
      <p>You must not use our website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website.</p>
      <p>When using our services, you agree to follow all applicable laws and regulations.</p>
      
      <h2 style={subHeadingStyle}>3. Third-Party Content</h2>
      <p>Our website includes free games provided by <a href="https://www.htmlgames.com/" style={linkStyle}>HTML Games</a>. These games are provided for your entertainment and are subject to their own terms of use and privacy policies. We are not responsible for the content or practices of HTML Games or any other third-party services linked to from our website.</p>
      
      <h2 style={subHeadingStyle}>4. Intellectual Property Rights</h2>
      <p>All content on this website, including but not limited to text, graphics, and logos, is the property of Nyx Play and is protected by copyright and intellectual property laws. You may not reproduce or distribute any content from our website without our express written permission.</p>
      
      <h2 style={subHeadingStyle}>5. User Accounts</h2>
      <p>If you create an account on our website, you are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</p>
      
      <h2 style={subHeadingStyle}>6. Termination</h2>
      <p>We reserve the right to terminate or suspend your access to our website at any time, without prior notice or liability, for any reason, including if you breach these Terms of Service.</p>
      
      <h2 style={subHeadingStyle}>7. Dispute Resolution</h2>
      <p>Any disputes arising out of or related to these Terms of Service or the use of our website shall be resolved through arbitration in accordance with the rules of the American Arbitration Association. The jurisdiction and venue for any legal action shall be in the state of [Your State], USA.</p>
      
      <h2 style={subHeadingStyle}>8. User-Generated Content</h2>
      <p>By submitting content to our website, you grant us a perpetual, non-exclusive, royalty-free license to use, reproduce, modify, and distribute such content. You are solely responsible for the content you submit and must ensure that it does not violate any laws or third-party rights.</p>
      
      <h2 style={subHeadingStyle}>9. Limitation of Liability</h2>
      <p>In no event shall Nyx Play be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the website. We make no warranties or representations about the accuracy or completeness of the content on the website.</p>
      
      <h2 style={subHeadingStyle}>10. Changes to Terms</h2>
      <p>We may update our Terms of Service from time to time. We will notify you of any changes by posting the new Terms of Service on this page. It is your responsibility to review these Terms periodically for any updates.</p>
      
      <p>If you have any questions about these Terms of Service, please contact us at <a href="mailto:support@nyxplay.com" style={linkStyle}>support@nyxplay.com</a>.</p>
    </div>
  );
};

export default TermsOfService;
