import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const GameItem = ({ game }) => {
  const [thumbnail, setThumbnail] = useState(game.thumb3);

  const handleError = () => {
    if (thumbnail === game.thumb3) {
      setThumbnail(game.thumb4);
    } else if (thumbnail === game.thumb4) {
      setThumbnail(game.thumb5);
    }
  };

  return (
    <div className="game-item">
      <Link to={`/game/${game.name}`}>
        <img 
          src={thumbnail} 
          alt={game.name} 
          className="thumbnail" 
          onError={handleError} 
        />
      </Link>
    </div>
  );
};

export default GameItem;
