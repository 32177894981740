// src/articles/BrookhavenArticle.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import the toast styles
import AdsenseAd from '../../components/AdsenseAd';
import './RobloxArticles.css'; // Use a common CSS

const BrookhavenArticle = () => {
  // Function to copy code to clipboard
  const copyToClipboard = (code) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(code)
        .then(() => {
          toast.success(`${code} copied to clipboard!`);
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          toast.error('Failed to copy code.');
        });
    } else {
      const tempInput = document.createElement('input');
      tempInput.value = code;
      document.body.appendChild(tempInput);
      tempInput.select();
      try {
        document.execCommand('copy');
        toast.success(`${code} copied to clipboard!`);
      } catch (err) {
        console.error('Failed to copy: ', err);
        toast.error('Failed to copy code.');
      } finally {
        document.body.removeChild(tempInput);
      }
    }
  };

  // Array of codes and their definitions
  const codes = [
    { code: 'BROOKHAVEN2024', description: 'Free house upgrade.' },
    { code: 'WELCOME', description: '1000 Bucks.' },
    { code: 'NEWYEAR2024', description: '500 Bucks.' },
    { code: 'SUMMER2024', description: 'Free vehicle upgrade.' },
    { code: 'VIPACCESS', description: 'Grants access to VIP areas.' },
    { code: 'FREECASH', description: 'Earn 2000 Bucks.' },
  ];

  return (
    <div className="article-container">
      <Helmet>
        <title>Brookhaven: Latest Codes & Tips for September 2024</title>
        <meta name="description" content="Discover the latest codes for Brookhaven, tips for role-playing, and exciting HTML games to play for free!" />
        <meta name="keywords" content="Brookhaven, Roblox, codes, tips, role-playing, gaming, HTML games, free games, community, houses" />
      </Helmet>

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={true} closeOnClick={true} pauseOnHover={false} draggable={false} />

      <h1>Brookhaven: Latest Codes & Tips for September 2024</h1>
      {/* Top Adsense Ad */}
      <div className="ads-container-top">
        <AdsenseAd id="adsense-top" slot="1456784065" />
      </div>

      <p>
        <strong>Brookhaven</strong> is a popular role-playing game on Roblox where players can build and furnish homes, drive vehicles, and engage in various activities. The game is well-known for its freedom and creativity. Using codes can help enhance your experience. In this article, we’ll explore the latest codes and tips for enjoying Brookhaven.
      </p>

      <h2>Latest Brookhaven Codes for September 2024</h2>
      <p>Each of these codes offers unique benefits that can enhance your gameplay:</p>

      {/* Codes Table */}
      <table className="codes-table">
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {codes.map(({ code, description }) => (
            <tr key={code}>
              <td><strong>{code}</strong></td>
              <td>{description}</td>
              <td>
                <button onClick={() => copyToClipboard(code)}>Copy</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p>
        Redeem these codes quickly as they may expire. Stay tuned for new codes that come out during special events and updates!
      </p>

      <h2>How to Redeem Brookhaven Codes</h2>
      <ol>
        <li>Launch <strong>Brookhaven</strong> on Roblox.</li>
        <li>Click the Codes button on the main menu.</li>
        <li>Enter your code and press enter to redeem.</li>
      </ol>

      <h2>Why Use Codes?</h2>
      <p>
        Utilizing codes in <strong>Brookhaven</strong> can offer free in-game currency, house upgrades, and more, allowing you to enjoy a richer gaming experience.
      </p>

      <h2>Tips for Enjoying Brookhaven</h2>
      <p>
        Engage with the community to learn about new updates and creative ideas for your home. Role-playing with friends enhances the experience, and participating in community events can lead to fun interactions.
      </p>

      <h2>Community and Updates</h2>
      <p>
        The Brookhaven community is vibrant and full of creative players. Follow social media channels to stay informed about new updates, features, and codes.
      </p>

      {/* Bottom Adsense Ad */}
      <div className="ads-container-bottom">
        <AdsenseAd id="adsense-bottom" slot="6918963927" />
      </div>

      {/* Promotional Section */}
      <div className="promotion-section">
        <h2>Exciting HTML Games: Free to Play!</h2>
        <p>Check out our main website for a variety of exciting HTML games that you can play for free! Enjoy thrilling adventures and fun challenges. Don’t miss out on the fun—play now and discover new games every day!</p>
        <a href="/" className="promotion-button">Play Now</a>
      </div>
    </div>
  );
};

export default BrookhavenArticle;
