// BMICalculator.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Calculator.css';

const BMICalculator = () => {
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [bmi, setBmi] = useState(null);
  const [category, setCategory] = useState('');

  const calculateBMI = () => {
    const heightInMeters = height / 100; // Convert height to meters
    const bmiValue = (weight / (heightInMeters * heightInMeters)).toFixed(2);
    setBmi(bmiValue);
    determineCategory(bmiValue);
  };

  const determineCategory = (bmiValue) => {
    if (bmiValue < 18.5) {
      setCategory('Underweight');
    } else if (bmiValue >= 18.5 && bmiValue < 24.9) {
      setCategory('Normal weight');
    } else if (bmiValue >= 25 && bmiValue < 29.9) {
      setCategory('Overweight');
    } else {
      setCategory('Obesity');
    }
  };

  return (
    <div className="calculator-container">
      <Helmet>
        <title>BMI Calculator</title>
        <meta name="description" content="Calculate your Body Mass Index (BMI) with our easy-to-use BMI calculator." />
      </Helmet>
      <h2 className="calculator-header">BMI Calculator</h2>
      <input
        type="number"
        className="calculator-input"
        placeholder="Weight (kg)"
        value={weight}
        onChange={(e) => setWeight(e.target.value)}
      />
      <input
        type="number"
        className="calculator-input"
        placeholder="Height (cm)"
        value={height}
        onChange={(e) => setHeight(e.target.value)}
      />
      <button className="calculator-button" onClick={calculateBMI}>Calculate BMI</button>
      {bmi && (
        <div className="calculator-result">
          Your BMI: {bmi} <br />
          Category: {category}
        </div>
      )}
      <article className="calculator-article">
        <h3>Understanding BMI</h3>
        <p>
          Body Mass Index (BMI) is a measure of body fat based on height and weight. 
          It's a simple calculation used to assess whether a person has a healthy body weight for a given height.
        </p>
      </article>
      <div className="bmi-category-chart">
        <h4>BMI Categories</h4>
        <ul>
          <li>Underweight: Less than 18.5</li>
          <li>Normal weight: 18.5 - 24.9</li>
          <li>Overweight: 25 - 29.9</li>
          <li>Obesity: 30 or greater</li>
        </ul>
      </div>
    </div>
  );
};

export default BMICalculator;
