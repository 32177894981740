import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import GameItem from './GameItem';
import { fetchGameById, fetchGames } from '../api'; // Import API functions
import { Helmet } from 'react-helmet';
import './GamePlayer.css';
import AdsenseAd from './AdsenseAd';

const GamePlayer = ({ onPlayStateChange }) => {
  const { id } = useParams();
  const navigate = useNavigate(); // Initialize useNavigate
  const [game, setGame] = useState(null);
  const [relatedGames, setRelatedGames] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGameData = async () => {
      setLoading(true);
      try {
        const data = await fetchGameById(id);
        if (!data) {
          // If game not found, redirect to home
          navigate('/');
          return;
        }
        setGame(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchGameData();
  }, [id, navigate]); // Add navigate to the dependency array

  useEffect(() => {
    if (game) {
      const fetchRelatedGamesData = async () => {
        try {
          const data = await fetchGames();
          const filteredRelatedGames = data.filter(
            (g) => g.category === game.category && g.name !== game.name
          );
          setRelatedGames(filteredRelatedGames);
        } catch (error) {
          setError(error.message);
        }
      };
      fetchRelatedGamesData();
    }
  }, [game]);

  const handlePlayClick = () => {
    setIsPlaying(true);
    onPlayStateChange(true);
  };

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="game-player">
      <Helmet>
        <title>{game.name} - Nyx Play</title>
        <meta name="description" content={game.description} />
        <meta property="og:title" content={`${game.name} - Nyx Play`} />
        <meta property="og:description" content={game.description} />
      </Helmet>
      {!isPlaying ? (
        <div className="thumbnail-container">
          <div
            className="thumbnail-blur-background"
            style={{ backgroundImage: `url(${game.thumb3})` }}
          ></div>
          <div className="thumbnail-overlay">
          <br />
            <img src={game.thumb3} alt={game.name} className="thumbnail-preview" />
            <h1>{game.name}</h1>
            <p>{game.description}</p>
            <button className="play-button" onClick={handlePlayClick}>
              Play Now
            </button>
            <br />
          </div>
        </div>
      ) : (
        <div className="iframe-container">
          <iframe
            src={game?.url}
            title={game?.name}
            frameBorder="0"
            sandbox="allow-same-origin allow-scripts"
            allowFullScreen
          ></iframe>
        </div>
      )}
      {/* Adsense Ad */}
      <div className="ads-container-top">
        <AdsenseAd id="adsense-top" slot="6918963927" />
      </div>
      {!isMobile && relatedGames.length > 0 && (
        <div className="related-games">
          <br />
          <a href="/" className="promotion-button">Menu</a>
          <br/>
          <h3>More games in {game.category} Category</h3>
          <div className="game-list">
            {relatedGames.map((relatedGame) => (
              <GameItem key={relatedGame.id} game={relatedGame} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GamePlayer;
