// TemperatureConverter.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Calculator.css';

const TemperatureConverter = () => {
  const [temperature, setTemperature] = useState('');
  const [unit, setUnit] = useState('Celsius');
  const [convertedTemp, setConvertedTemp] = useState(null);

  const convertTemperature = () => {
    let result;
    if (unit === 'Celsius') {
      result = (temperature * 9/5) + 32; // Celsius to Fahrenheit
      setConvertedTemp(`${result.toFixed(2)} °F`);
    } else {
      result = (temperature - 32) * 5/9; // Fahrenheit to Celsius
      setConvertedTemp(`${result.toFixed(2)} °C`);
    }
  };

  return (
    <div className="calculator-container">
      <Helmet>
        <title>Temperature Converter</title>
        <meta name="description" content="Convert temperatures between Celsius and Fahrenheit with our easy-to-use converter." />
      </Helmet>
      <h2 className="calculator-header">Temperature Converter</h2>
      <input
        type="number"
        className="calculator-input"
        placeholder="Temperature"
        value={temperature}
        onChange={(e) => setTemperature(e.target.value)}
      />
      <select className="calculator-select" value={unit} onChange={(e) => setUnit(e.target.value)}>
        <option value="Celsius">Celsius to Fahrenheit</option>
        <option value="Fahrenheit">Fahrenheit to Celsius</option>
      </select>
      <button className="calculator-button" onClick={convertTemperature}>Convert</button>
      {convertedTemp && <div className="calculator-result">Converted Temperature: {convertedTemp}</div>}
      <article className="calculator-article">
        <h3>Temperature Conversion</h3>
        <p>
          Temperature conversion helps you switch between different temperature units. 
          The two most common scales are Celsius and Fahrenheit. Use this converter for quick results.
        </p>
      </article>
    </div>
  );
};

export default TemperatureConverter;
