// GameList.js
import React, { useState, useEffect } from 'react';
import GameItem from './GameItem';
import CategoryFilter from './CategoryFilter';
import { fetchGames } from '../api'; // Import fetchGames function
import './GameList.css'; // Import the CSS file
import AdsenseAd from './AdsenseAd';

const PAGE_SIZE = 20; // Number of games per page
const DAILY_PAGE_SIZE = 2; // Number of daily games per page

const GameList = () => {
  const [gamesData, setGamesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [currentPage, setCurrentPage] = useState(() => Number(localStorage.getItem('currentPage')) || 1);
  const [dailyCurrentPage, setDailyCurrentPage] = useState(1); // For daily games pagination
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchGamesData = async () => {
      setLoading(true);
      try {
        const data = await fetchGames();
        setGamesData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchGamesData();
  }, []);

  // Filter Daily Games and exclude specific games
  const dailyGames = gamesData.filter((game) => 
    game.category === 'Daily Puzzles' && 
    game.name !== 'Daily Worms' && 
    game.name !== 'Daily Net'
  );

  // Filter other games based on category and search query
  const filteredGames = gamesData.filter((game) =>
    (selectedCategory === 'All' || game.category === selectedCategory) &&
    game.category !== 'Daily Games' && // Exclude Daily Games from regular games list
    game.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const paginatedGames = filteredGames.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredGames.length / PAGE_SIZE);

  const dailyStartIndex = (dailyCurrentPage - 1) * DAILY_PAGE_SIZE;
  const dailyEndIndex = dailyStartIndex + DAILY_PAGE_SIZE;
  const paginatedDailyGames = dailyGames.slice(dailyStartIndex, dailyEndIndex);

  const dailyTotalPages = Math.ceil(dailyGames.length / DAILY_PAGE_SIZE);

  const handlePageChange = (pageNumber) => {
    localStorage.setItem('currentPage', pageNumber);
    setCurrentPage(pageNumber);
  };

  const handleDailyPageChange = (pageNumber) => {
    setDailyCurrentPage(pageNumber);
  };

  const getPaginationButtons = () => {
    const buttons = [];

    if (totalPages <= 5) {
      // Show all page buttons if there are 5 or fewer pages
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? 'active' : ''}
            aria-label={`Page ${i}`}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show first page
      buttons.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={currentPage === 1 ? 'active' : ''}
          aria-label="Page 1"
        >
          1
        </button>
      );

      // Add ellipsis if there are more than 3 pages in between
      if (currentPage > 3) {
        buttons.push(<span key="left-ellipsis">...</span>);
      }

      // Show a range of pages around the current page
      const start = Math.max(2, currentPage - 1);
      const end = Math.min(totalPages - 1, currentPage + 1);

      for (let i = start; i <= end; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? 'active' : ''}
            aria-label={`Page ${i}`}
          >
            {i}
          </button>
        );
      }

      // Add ellipsis if there are more than 3 pages after the current page
      if (currentPage < totalPages - 2) {
        buttons.push(<span key="right-ellipsis">...</span>);
      }

      // Show last page
      buttons.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={currentPage === totalPages ? 'active' : ''}
          aria-label={`Page ${totalPages}`}
        >
          {totalPages}
        </button>
      );
    }

    return buttons;
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory, searchQuery]);

  useEffect(() => {
    localStorage.setItem('currentPage', currentPage);
  }, [currentPage]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner" aria-label="Loading..."></div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="game-list-container">
      <CategoryFilter
        selectedCategory={selectedCategory}
        onCategoryChange={setSelectedCategory}
      />
      <p>Enjoy the finest selection of free online HTML and HTML5 games for mobile, tablet, and desktop. Our games are compatible with iOS (iPhone, iPad), Android, and PC/Mac.</p>
      <input
        type="text"
        placeholder="Search games..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
        aria-label="Search games"
      />

      {/* Top Adsense Ad */}
      <div className="ads-container-top">
        <AdsenseAd id="adsense-top" slot="1456784065" />
      </div>

      {/* Daily Games Section */}
      {paginatedDailyGames.length > 0 && (
        <div className="daily-games-container">
          <h2>Daily Challenge</h2>
          <div className="daily-games">
            {paginatedDailyGames.map((game) => (
              <GameItem key={game.name} game={game} />
            ))}
          </div>
          <div className="daily-pagination-controls">
            <button
              onClick={() => handleDailyPageChange(dailyCurrentPage - 1)}
              disabled={dailyCurrentPage === 1}
              aria-label="Previous daily page"
            >
              &lt; {/* Left arrow */}
            </button>
            <span>
              Page {dailyCurrentPage} of {dailyTotalPages}
            </span>
            <button
              onClick={() => handleDailyPageChange(dailyCurrentPage + 1)}
              disabled={dailyCurrentPage === dailyTotalPages}
              aria-label="Next daily page"
            >
              &gt; {/* Right arrow */}
            </button>
          </div>
        </div>
      )}

      {/* Regular Games Section */}
      <div className="game-list">
        {paginatedGames.map((game) => (
          <GameItem key={game.name} game={game} />
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          aria-label="Previous page"
        >
          Previous
        </button>
        {getPaginationButtons()}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          aria-label="Next page"
        >
          Next
        </button>
      </div>

      {/* Bottom Adsense Ad */}
      <br />
      <div className="ads-container-bottom">
        <AdsenseAd id="adsense-bottom" slot="6918963927" />
      </div>
    </div>
  );
};

export default GameList;
