// BasicCalculator.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Calculator.css';

const BasicCalculator = () => {
  const [num1, setNum1] = useState('');
  const [num2, setNum2] = useState('');
  const [result, setResult] = useState(null);

  const calculate = (operation) => {
    let res;
    switch (operation) {
      case 'add':
        res = parseFloat(num1) + parseFloat(num2);
        break;
      case 'subtract':
        res = parseFloat(num1) - parseFloat(num2);
        break;
      case 'multiply':
        res = parseFloat(num1) * parseFloat(num2);
        break;
      case 'divide':
        res = num2 !== '0' ? parseFloat(num1) / parseFloat(num2) : 'Cannot divide by zero';
        break;
      default:
        return;
    }
    setResult(res);
  };

  return (
    <div className="calculator-container">
      <Helmet>
        <title>Basic Calculator</title>
        <meta name="description" content="Perform basic arithmetic operations with our easy-to-use calculator." />
      </Helmet>
      <h2 className="calculator-header">Basic Calculator</h2>
      <input
        type="number"
        className="calculator-input"
        placeholder="Number 1"
        value={num1}
        onChange={(e) => setNum1(e.target.value)}
      />
      <input
        type="number"
        className="calculator-input"
        placeholder="Number 2"
        value={num2}
        onChange={(e) => setNum2(e.target.value)}
      />
      <div>
        <button className="calculator-button" onClick={() => calculate('add')}>Add</button>
        <button className="calculator-button" onClick={() => calculate('subtract')}>Subtract</button>
        <button className="calculator-button" onClick={() => calculate('multiply')}>Multiply</button>
        <button className="calculator-button" onClick={() => calculate('divide')}>Divide</button>
      </div>
      {result !== null && <div className="calculator-result">Result: {result}</div>}
      <article className="calculator-article">
        <h3>About Basic Calculator</h3>
        <p>
          A basic calculator is a simple device used to perform basic mathematical operations such as addition, 
          subtraction, multiplication, and division. This calculator helps you perform calculations quickly and easily.
        </p>
      </article>
    </div>
  );
};

export default BasicCalculator;
