import React, { useEffect } from 'react';

const AdsenseAd = ({ id, slot }) => {
    useEffect(() => {
        // Load AdSense script
        const script = document.createElement('script');
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7775188281130406";
        script.async = true;
        script.setAttribute('crossorigin', 'anonymous');
        document.body.appendChild(script);

        // Create the ad element
        const adElement = document.createElement('ins');
        adElement.className = 'adsbygoogle';
        adElement.style.display = 'block';
        adElement.style.textAlign = 'center';
        adElement.setAttribute('data-ad-layout', 'in-article');
        adElement.setAttribute('data-ad-format', 'fluid');
        adElement.setAttribute('data-ad-client', 'ca-pub-7775188281130406');
        adElement.setAttribute('data-ad-slot', slot);  // Use the passed slot

        // Append ad element to container
        const container = document.getElementById(id);  // Use the passed container ID
        container.appendChild(adElement);

        // Push the ad to Google AdSense
        (window.adsbygoogle = window.adsbygoogle || []).push({});

        return () => {
            document.body.removeChild(script);
            container.removeChild(adElement);
        };
    }, [id, slot]);

    return <div id={id}></div>;
};

export default AdsenseAd;
