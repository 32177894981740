// WeightConverter.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Calculator.css';

const WeightConverter = () => {
  const [weight, setWeight] = useState('');
  const [unit, setUnit] = useState('Kilograms');
  const [convertedWeight, setConvertedWeight] = useState(null);

  const convertWeight = () => {
    let result;
    if (unit === 'Kilograms') {
      result = weight * 2.20462; // Kilograms to Pounds
      setConvertedWeight(`${result.toFixed(2)} lbs`);
    } else {
      result = weight / 2.20462; // Pounds to Kilograms
      setConvertedWeight(`${result.toFixed(2)} kg`);
    }
  };

  return (
    <div className="calculator-container">
      <Helmet>
        <title>Weight Converter</title>
        <meta name="description" content="Convert weights between Kilograms and Pounds with our easy-to-use converter." />
      </Helmet>
      <h2 className="calculator-header">Weight Converter</h2>
      <input
        type="number"
        className="calculator-input"
        placeholder="Weight"
        value={weight}
        onChange={(e) => setWeight(e.target.value)}
      />
      <select className="calculator-select" value={unit} onChange={(e) => setUnit(e.target.value)}>
        <option value="Kilograms">Kilograms to Pounds</option>
        <option value="Pounds">Pounds to Kilograms</option>
      </select>
      <button className="calculator-button" onClick={convertWeight}>Convert</button>
      {convertedWeight && <div className="calculator-result">Converted Weight: {convertedWeight}</div>}
      <article className="calculator-article">
        <h3>Weight Conversion</h3>
        <p>
          Weight conversion allows you to switch between different weight units. 
          The most common units are kilograms and pounds. This converter provides quick and accurate results.
        </p>
      </article>
    </div>
  );
};

export default WeightConverter;
