import React from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import the toast styles
import AdsenseAd from '../../components/AdsenseAd';
import './RobloxArticles.css';

const MurderMystery2Article = () => {
  // Function to copy code to clipboard and show a toast
  const copyToClipboard = (code) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(code)
        .then(() => {
          toast.success(`${code} copied to clipboard!`);
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          toast.error('Failed to copy code.');
        });
    } else {
      const tempInput = document.createElement('input');
      tempInput.value = code;
      document.body.appendChild(tempInput);
      tempInput.select();
      try {
        document.execCommand('copy');
        toast.success(`${code} copied to clipboard!`);
      } catch (err) {
        console.error('Failed to copy: ', err);
        toast.error('Failed to copy code.');
      } finally {
        document.body.removeChild(tempInput);
      }
    }
  };

  // Array of codes and their descriptions
  const codes = [
    { code: 'MM2HOLIDAY', description: 'Free Knife' },
    { code: 'MM2CODE', description: '10% off items' },
    { code: 'MM2GOLD', description: 'Special Effects' },
    { code: 'MM2FALL2024', description: 'Exclusive Fall Item' },
    { code: 'MM2GIFT', description: 'Gift for players' },
  ];

  return (
    <div className="article-container">
      <Helmet>
        <title>Murder Mystery 2: Latest Codes & Tips for September 2024</title>
        <meta name="description" content="Discover the latest codes for Murder Mystery 2, tips for surviving, and exciting HTML games to play for free!" />
        <meta name="keywords" content="Murder Mystery 2, Roblox, codes, tips, gameplay, gaming, HTML games, free games, detective, murder" />
      </Helmet>

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={true} closeOnClick={true} pauseOnHover={false} draggable={false} />

      <h1>Murder Mystery 2: Latest Codes & Tips for September 2024</h1>
      
      {/* Top Adsense Ad */}
      <div className="ads-container-top">
        <AdsenseAd id="adsense-top" slot="1456784065" />
      </div>

      <p>
        <strong>Murder Mystery 2</strong> is a thrilling game on Roblox that challenges players to solve mysteries and identify the murderer among them. With various roles and engaging gameplay, it keeps players on their toes. Using codes can provide essential benefits that enhance your gameplay experience. In this article, we’ll share the latest codes and strategies for winning.
      </p>

      <h2>Latest Murder Mystery 2 Codes for September 2024</h2>
      <p>Each of these codes offers unique benefits that can enhance your gameplay:</p>

      {/* Codes Table */}
      <table className="codes-table">
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {codes.map(({ code, description }) => (
            <tr key={code}>
              <td><strong>{code}</strong></td>
              <td>{description}</td>
              <td>
                <button onClick={() => copyToClipboard(code)}>Copy</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p>
        Be sure to redeem these codes as soon as possible since they can expire quickly. Regular updates bring new codes, so always check for the latest!
      </p>

      <h2>How to Redeem Murder Mystery 2 Codes</h2>
      <ol>
        <li>Open <strong>Murder Mystery 2</strong> on Roblox.</li>
        <li>Click on the Inventory button.</li>
        <li>Enter your code in the text box and click redeem.</li>
      </ol>

      <h2>Why Use Codes?</h2>
      <p>
        Codes in <strong>Murder Mystery 2</strong> can provide you with free weapons, exclusive items, and discounts, enhancing your gameplay and strategy in the game.
      </p>

      <h2>Strategies for Winning</h2>
      <p>
        Always pay attention to player behavior and movements. Communication with other players is key, and understanding the map layout can give you an advantage. Don’t rush your decisions; take your time to gather information before acting!
      </p>

      <h2>Community and Updates</h2>
      <p>
        The Murder Mystery 2 community is active, and players often share tips and strategies. Stay updated by following the game’s social media for announcements regarding new codes and features.
      </p>

      {/* Bottom Adsense Ad */}
      <div className="ads-container-bottom">
        <AdsenseAd id="adsense-bottom" slot="6918963927" />
      </div>

      {/* Promotional Section */}
      <div className="promotion-section">
        <h2>Exciting HTML Games: Free to Play!</h2>
        <p>Check out our main website for a variety of exciting HTML games that you can play for free! Enjoy thrilling adventures and fun challenges. Don’t miss out on the fun—play now and discover new games every day!</p>
        <a href="/" className="promotion-button">Play Now</a>
      </div>
    </div>
  );
};

export default MurderMystery2Article;
