// api.js

const API_URL = 'https://nyx-play-api.onrender.com/proxy/games';

/**
 * Fetches the list of games from the API.
 * @returns {Promise<Object[]>} The list of games.
 * @throws {Error} If the request fails or the response is not OK.
 */
const fetchGames = async () => {
  try {
    const response = await fetch(API_URL);

    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(`Failed to fetch games data: ${response.status} ${response.statusText} - ${errorDetails}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching games:', error.message);
    throw new Error(error.message);
  }
};

/**
 * Fetches a specific game by its ID from the API.
 * Since the new API does not have an ID structure, we'll assume it's a manual filter.
 * @param {number|string} id - The ID of the game to fetch.
 * @returns {Promise<Object|null>} The game data or null if not found.
 * @throws {Error} If the request fails or the response is not OK.
 */
const fetchGameById = async (id) => {
  try {
    const games = await fetchGames();
    
    const game = games.find(game => game.name === id);

    // Return null if game not found
    return game || null; 
  } catch (error) {
    console.error('Error fetching game by ID:', error.message);
    throw new Error(error.message);
  }
};


module.exports = { fetchGames, fetchGameById };
