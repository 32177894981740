// CategoryFilter.js
import React, { useState } from 'react';
import './CategoryFilter.css'; // Ensure to create this CSS file

const CategoryFilter = ({ selectedCategory, onCategoryChange }) => {
  const [isVisible, setIsVisible] = useState(false); // Default to hidden

  const categories = [
    'All', 'Hidden Object Games', 'Match 3 Games', '2048 & Merge',
    'Pyramid', 'Platform', 'Freecell', 'Mahjong Connect', 'Escape Games',
    'Tile Games', 'Bubble Shooter', 'Klondike', 'Sorting Games',
    'Shooting & War', 'Maze Games', 'Mahjong Solitaire',
    'Connect 3', 'Solitaire Games', 'Puzzle Games',
    'Mahjong Slide', 'Brain Games', 'Skill',
    'Arkanoid', 'Collapse Games', 'Daily Puzzles',
    'Tripeaks & Golf', 'Bejeweled', 'Card Games',
    'Puzzles', 'Hidden Clues', 'Montana',
    'Zuma Games', '3D Mahjong', 'Pinball',
    'Memory', 'Tetris', 'Spider',
    'Board', 'Word Games', 'Racing',
    'Mahjong Games', 'Tower Defense', 'Difference Games',
    'Math Games', 'Sports', 'Retro',
    'Billiards', 'Golf', 'Time management',
    'Hidden Alphabet', 'Crosswords', 'Sudoku',
    'Mahjong Tower', 'Snake', 'Hidden Numbers',
    'Pac Maze'
  ];

  return (
    <div className="category-filter">
      <button 
        className="toggle-button" 
        onClick={() => setIsVisible(!isVisible)}
        aria-label={isVisible ? "Hide categories" : "Show categories"}
      >
        {isVisible ? "Hide Categories" : "Show Categories"}
      </button>
      {isVisible && (
        <div className="category-buttons">
          {categories.map((category) => (
            <button
              key={category}
              className={`category-button ${selectedCategory === category ? 'active' : ''}`}
              onClick={() => onCategoryChange(category)}
              aria-label={`Select ${category} category`}
            >
              {category}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryFilter;
